@import "./base.scss";

.frf-trigger-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 12px;
  bottom: 24px;
  white-space: nowrap;
  cursor: pointer;
  @include shadow;
  padding: 16px 30px 16px;
  outline: 0;
  border-radius: 4px;
  border: none;

  .frf-feedback-icon,
  .frf-feedback-icon-open {
    display: flex;
    align-items: center;
  }

  &:hover {
    border-color: $black;
  }

  * {
    font-size: 16px;
    font-weight: 500;
  }
}

.frf-dialog-enter {
  opacity: 0;
}

.frf-dialog-enter-active {
  animation-name: fadeIn;
  animation-duration: 300ms;
  opacity: 1;
  transition: opacity 300ms;
}

.frf-dialog-exit {
  opacity: 1;
}

.frf-dialog-exit-active {
  animation-name: fadeOut;
  animation-duration: 300ms;
  opacity: 0.01;
  transition: opacity 300ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0px);
  }

  to {
    opacity: 0;
    transform: translateY(5px);
  }
}

// Only do hover effects on non-touch devices
@media (hover: hover) {
  .frf-trigger-button {
    transition: opacity 0.2s 0s ease, font-size 0.2s 0.2s ease;

    &:hover {
      .frf-feedback-icon:after {
        content: "Send Feedback";
        margin-left: 8px;
        font-size: inherit;
        opacity: 1;
        transition: font-size 0.2s 0s ease, opacity 0.2s 0s ease;
      }

      .frf-feedback-icon-open:after {
        content: "Close Form";
        font-size: inherit;
        opacity: 1;
        transition: font-size 0.2s 0s ease, opacity 0.2s 0s ease;
      }
    }

    .frf-feedback-icon:after {
      content: "Send Feedback";
      padding-left: 4px;
      font-size: 0;
      opacity: 0;
      transition: opacity 0.2s 0s ease, font-size 0.2s 0s ease;
    }

    .frf-feedback-icon-open:after {
      content: "Close Form";
      padding-left: 4px;
      font-size: 0;
      opacity: 0;
      transition: opacity 0.2s 0s ease, font-size 0.2s 0s ease;
    }
  }
}
