/*Base Styles*/

html {
  height: 100%;
}

:root {
  font-size: 16px;
}

body {
  font: {
    family: $font-family-base;
    size: $font-size-base;
    weight: $font-weight-base;
  }

  height: 100%;
  overflow: hidden;
}

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important;
}

.myfrom {
  border: thin solid rgb(0, 0, 0);
  padding-left: 10px;
  padding-right: 10px;
  margin: 1rem;
  display: flex;
  border-radius: 4px;
}

.formtext {
  position: relative;
  top: -10px;
  background: white;
  padding-left: 7px;
  padding-right: 7px;
  width: auto;
  color: rgb(3, 3, 3);
}

#loader {
  position: absolute;
  left: 60%;
  top: 55%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #e42967;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

#nprogress .bar {
  z-index: 5000 !important;
  background: $secondary !important;
}

#nprogress .peg {
  @include box-shadow(0 0 10px $secondary, 0 0 5px $secondary !important);
}

#nprogress .spinner {
  z-index: 5000 !important;
}

#nprogress .spinner-icon {
  border-top-color: $secondary !important;
  border-left-color: $secondary !important;
}

.ripple-effect {
  @extend %ripple-effect;
}

.text-transform-none {
  text-transform: none;
}

.br-break {
  word-break: break-all !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.z-index-20 {
  z-index: 20;
}

.size-120 {
  @extend %size-120;
}

.size-100 {
  @extend %size-100;
}

.size-90 {
  @extend %size-90;
}

.size-80 {
  @extend %size-80;
}

.size-70 {
  @extend %size-70;
}

.size-60 {
  @extend %size-60;
}

.size-50 {
  @extend %size-50;
}

.size-40 {
  @extend %size-40;
}

.size-30 {
  @extend %size-30;
}

.size-30 {
  @extend %size-30;
}

.size-20 {
  @extend %size-20;
}

.size-10 {
  @extend %size-10;
}

.size-8 {
  @extend %size-8;
}

.border-bottom {
  border-bottom: solid 1px $gray-300;
}

div[tabindex="-1"] {
  z-index: 1300 !important; // updated by sergey 2021.03.25 - origin 1200
}

div.MuiChip-root[tabindex="-1"] {
  z-index: 0 !important;
}

.joyride-overlay {
  z-index: 1510 !important;
}

.col-with-divider div[class*="col-"] {
  border-left: solid 1px $gray-300;

  &:first-child {
    border: 0 none;
  }
}

.height-100 {
  height: 100px !important;
}

.height-150 {
  height: 150px !important;
}

.height-200 {
  height: 200px !important;
}

.max-width-100 {
  max-width: 100px !important;
}

.max-height-120 {
  max-height: 120px !important;
}

.max-height-450 {
  max-height: 450px !important;
}

.right-arrow {
  display: inline-block;
  color: $app-primary;
  text-transform: capitalize;

  &:after {
    content: "\f30f";
    font-family: "Material-Design-Iconic-Font";
    color: $app-primary;
    margin-left: 8px;
    font-size: 20px;
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.letter-spacing-base {
  letter-spacing: $letter-spacing-base;
}

.letter-spacing-lg {
  letter-spacing: $letter-spacing-lg;
}

.letter-spacing-xl {
  letter-spacing: $letter-spacing-xl;
}

.page-heading {
  @extend %jr-card-style;
  padding: 22px 30px;
  z-index: 2;

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
    padding: 16px 20px;
  }

  & .title {
    text-transform: capitalize;
    font-weight: 700;
  }

  & .breadcrumb {
    padding: 0 !important;
    background-color: transparent !important;
    margin-bottom: 0;
    font-size: 13px;
    display: flex;
    align-items: center;

    &-item {
      color: $light-gray;
      display: flex;
      align-items: center;

      &.active {
        color: $app-primary;
      }
    }
  }
}

.sub-heading {
  font-size: 13px;
  color: $sub-heading-color;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 16px !important;
  }
}

.manage-margin {
  margin-bottom: 0;

  &>* {
    margin-bottom: 6px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

//Shadow
.no-shadow {
  @include box-shadow(none !important);
}

.shadow-sm {
  @include box-shadow($shadow-sm);
}

.shadow {
  @include box-shadow($shadow);
}

.shadow-lg {
  @include box-shadow($shadow-lg);
}

.shadow-xl {
  @include box-shadow($shadow-xl);
}

.avatar-shadow {
  @include box-shadow($avatar-shadow);
}

.remove-table-border {

  td,
  th {
    border: 0 none;
  }
}

//Media Object list
.media-list {
  margin-bottom: 10px;

  &:not(:last-child) {
    border-bottom: solid 1px $gray-200;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative;
  }
}

.border-2 {
  border: 2px solid $gray-400 !important;
}

.border-w2 {
  border-width: 2px;
}

// Border Color Classes
@each $color_name,
$color in $colors_palette {

  @each $color_type,
  $color_value in $color {
    @if $color_type =="base" {
      .border-#{$color_name} {
        border-color: $color_value !important;
      }
    }
  }
}

// Badge Color Classes
@each $color_name,
$color in $colors_palette {

  @each $color_type,
  $color_value in $color {
    @if $color_type =="base" {
      .badge-#{$color_name} {
        color: $white;
        background-color: $color_value !important;

        &[href]:hover,
        &[href]:focus {
          text-decoration: none;
          background-color: darken($color_value, 10%) !important;
        }
      }
    }
  }
}

//Center Crop image

.center-crop-img {
  position: relative;
  width: 100%;
  min-height: 118px;
  overflow: hidden;

  & img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  & img.portrait {
    width: 100%;
    height: auto;
  }
}

//Bootstrap Components Override

//Buttons

a.btn-info {
  @extend %link-hover;
}

a.btn-danger {
  @extend %link-hover;
}

a.btn-warning {
  @extend %link-hover;
}

a.btn-success {
  @extend %link-hover;
}

a.btn-dark {
  @extend %link-hover;
}

//Badges
a.badge-success {
  @extend %link-hover;
}

a.badge-danger {
  @extend %link-hover;
}

a.badge-warning {
  @extend %link-hover;
}

a.badge-info {
  @extend %link-hover;
}

a.badge-dark {
  @extend %link-hover;
}

a.badge-pink {
  @extend %link-hover;
}

a.badge-red {
  @extend %link-hover;
}

a.badge-purple {
  @extend %link-hover;
}

a.badge-deep-purple {
  @extend %link-hover;
}

a.badge-indigo {
  @extend %link-hover;
}

a.badge-blue {
  @extend %link-hover;
}

a.badge-light-blue {
  @extend %link-hover;
}

a.badge-cyan {
  @extend %link-hover;
}

a.badge-teal {
  @extend %link-hover;
}

a.badge-green {
  @extend %link-hover;
}

a.badge-light-green {
  @extend %link-hover;
}

a.badge-lime {
  @extend %link-hover;
}

a.badge-yellow {
  @extend %link-hover;
}

a.badge-amber {
  @extend %link-hover;
}

a.badge-orange {
  @extend %link-hover;
}

a.badge-deep-orange {
  @extend %link-hover;
}

a.badge-brown {
  @extend %link-hover;
}

a.badge-blue-gray {
  @extend %link-hover;
}

a.badge-gray {
  @extend %link-hover;
}

.pointer {
  cursor: pointer;
}

.font-size-20 {
  font-size: 20px !important;
}

//Rounded
.rounded-sm {
  @include border-radius($border-radius-sm !important);
}

.rounded-lg {
  @include border-radius($border-radius-lg !important);
}

.rounded-xl {
  @include border-radius($border-radius-xl !important);
}

.rounded-top-left {
  border-top-left-radius: $border-radius !important;
}

.rounded-top-right {
  border-top-right-radius: $border-radius !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: $border-radius !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: $border-radius !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

//Light Color
.text-light {
  color: lighten($black, 60%) !important;
}

.text-light-grey {
  color: $light-gray !important;
}

a.text-light {

  &:hover,
  &:focus {
    background-color: lighten($black, 50%) !important;
  }
}

//Sepia Color
.bg-sepia {
  background-color: $sepia !important;
}

a.bg-sepia {

  &:hover,
  &:focus {
    background-color: darken($sepia, 10%) !important;
  }
}

.text-sepia {
  color: $sepia;
}

a.text-sepia {

  &:hover,
  &:focus {
    color: $sepia !important;
  }
}

.border-sepia {
  border-color: $sepia !important;
}

.btn-sepia,
a.btn-sepia {
  color: $white !important;
  background-color: $sepia !important;
  border-color: $sepia !important;

  &:hover,
  &:focus {
    color: $white !important;
    background-color: darken($sepia, 15%) !important;
    border-color: darken($sepia, 15%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: $sepia !important;
    border-color: $sepia !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: $white !important;
    background-color: darken($sepia, 15%) !important;
    border-color: darken($sepia, 15%) !important;
  }
}

.btn-outline-sepia,
a.btn-outline-sepia {
  color: $white !important;
  background-color: transparent !important;
  border-color: $sepia !important;

  &:hover,
  &:focus {
    color: $white !important;
    background-color: $sepia !important;
    border-color: $sepia !important;
  }

  &.disabled,
  &:disabled {
    color: $sepia !important;
    background-color: transparent !important;
  }
}

.badge-sepia {
  background-color: $sepia !important;
}

//Sepia Color
.bg-geekblue {
  background-color: $geekblue !important;
}

a.bg-geekblue {

  &:hover,
  &:focus {
    background-color: darken($geekblue, 10%) !important;
  }
}

.text-geekblue {
  color: $geekblue;
}

a.text-geekblue {

  &:hover,
  &:focus {
    color: $geekblue !important;
  }
}

.border-geekblue {
  border-color: $geekblue !important;
}

.btn-geekblue,
a.btn-geekblue {
  color: $white !important;
  background-color: $geekblue !important;
  border-color: $geekblue !important;

  &:hover,
  &:focus {
    color: $white !important;
    background-color: darken($geekblue, 15%) !important;
    border-color: darken($geekblue, 15%) !important;
  }

  &.disabled,
  &:disabled {
    background-color: $geekblue !important;
    border-color: $geekblue !important;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: $white !important;
    background-color: darken($geekblue, 15%) !important;
    border-color: darken($geekblue, 15%) !important;
  }
}

.btn-outline-geekblue,
a.btn-outline-geekblue {
  color: $white !important;
  background-color: transparent !important;
  border-color: $geekblue !important;

  &:hover,
  &:focus {
    color: $white !important;
    background-color: $geekblue !important;
    border-color: $geekblue !important;
  }

  &.disabled,
  &:disabled {
    color: $geekblue !important;
    background-color: transparent !important;
  }
}

.badge-geekblue {
  background-color: $geekblue !important;
}

//Separator
.jr-separator {
  background-color: $app-primary;
  width: $size-20;
  height: 2px;
  margin: 0 auto 10px;
}

.object-cover {
  object-fit: cover;
}

.jr-border-radius {
  @include border-radius($border-radius);
}

// Custom List
.jr-list {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;

  &__item {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 14px;
  }

  &-bordered &-item:not(:last-child) {
    border-right: $jr-border;
  }

  &-half &-item {
    width: 50%;

    &:nth-last-of-type(1),
    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }
}

// Gradient Color Class
.bg-gradient-primary {
  @include gradient-directional($app-primary, lighten($app-primary, 16%), 0deg);
}

.bg-gradient-primary-x {
  @include gradient-x(darken($app-primary, 10%),
    lighten($app-primary, 16%),
    70%,
    100%);
}

.overflow-hidden {
  overflow: hidden;
}

.embed-responsive-31by9:before {
  padding-top: 28%;

  @media screen and (max-width: 575px) {
    padding-top: 36%;
  }
}

.col-half {
  @media screen and (max-width: 400px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.room-scrollbar {
  height: calc(100% - 145px) !important;
}

.room-comment {
  min-height: 200px;

  @media screen and (max-width: 960px) {
    min-height: 300px;
  }
}

.comment_modal {
  @media screen and (min-width: 576px) {
    max-width: 80% !important;
  }

  @media screen and (min-width: 1200px) {
    left: 5%;
    max-width: 70% !important;
  }
}

.pre-scrollable {
  max-height: 495px !important;
}

.label-purple {
  height: 20px;
  background: purple;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-purple:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid purple;
}

.label-orange {
  height: 20px;
  background: orange;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-orange:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid orange;
}

.label-green {
  height: 20px;
  background: green;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-green:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid green;
}

.label-cyan {
  height: 20px;
  background: cyan;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-cyan:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid cyan;
}

.label-red {
  height: 20px;
  background: red;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-red:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid red;
}

.label-pink {
  height: 20px;
  background: pink;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-pink:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid pink;
}

.label-indigo {
  height: 20px;
  background: indigo;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-indigo:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid indigo;
}

.label-lime {
  height: 20px;
  background: lime;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-lime:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid lime;
}

.label-warning {
  height: 20px;
  background: warning;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-warning:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid warning;
}

.label-teal {
  height: 20px;
  background: teal;
  color: #fff;
  position: relative;
  width: auto;
  text-align: center;
  line-height: 20px;
}

.label-teal:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 10px solid transparent;
  border-left: 10px solid teal;
}

.writing {
  padding-left: 10px;
  margin-left: 50px;
}

.bubble-message {
  position: relative;
  overflow: visible;
  background-color: transparent;
  flex-grow: 0;
  flex-shrink: 0;
  width: 30px;
  height: 15px;
  top: 1px;
  opacity: 0.8;
  margin-left: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("/light.gif");
  background-size: contain;
  border-style: none;
  display: flex;
}

.chat-textarea:focus {
  box-shadow: none !important;
}

.opacity-1 {
  opacity: 0.2;
}

.opacity-2 {
  opacity: 0.4;
}

.opacity-3 {
  opacity: 0.6;
}

.opacity-4 {
  opacity: 0.8;
}

.opacity-5 {
  opacity: 1;
}

.bg-bright-red {
  background: rgba(251, 108, 108, 0.8);
}

.drop-height-50 {
  height: 50px !important;
}

.SortableItem canvas {
  width: 300px;
  height: 150px;
}

div.top-scrollbars {
  transform: rotateX(180deg);
}

.bg-dark-blue {
  background-color: #272f60;
}

.modal-box.merge-modal {
  margin-top: 6rem;
}

.react-form-builder-form canvas {
  border: black 1px solid;
}

.setting-time .MuiOutlinedInput-input {
  padding: 10px;
}

.stepper {
  padding: 14px 30px;
  border-radius: 0.125rem;
  position: relative;
  border: 0 none;
  color: white;
  display: inline-block;
  height: 60px;
}

.stepper0 {
  padding: 14px 30px;
  border-radius: 0.125rem;
  position: relative;
  border: 0 none;
  color: white;
  display: inline-block;
  height: 60px;
}

.stepper::after {
  border-left: 20px solid;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  display: inline-block;
  content: "";
  position: absolute;
  right: -20px;
  z-index: 3;
  top: 0;
}

.stepper::before {
  border-left: 20px solid;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  display: inline-block;
  content: "";
  position: absolute;
  right: 0px;
  z-index: 3;
  top: 0;
}

.stepper-deactive {
  background-color: #d6d8d9;
  background: linear-gradient(to right, #6f6f6f, #d6d8d9);
}

.stepper-deactive::after {
  color: #f4f4f7;
}

.stepper-deactive::before {
  background-color: #f4f4f7;
  color: #d1d3d4;
}

.stepper-1 {
  background-color: green;
  background: linear-gradient(to right, green, #00ce00);
}

.stepper-1::after {
  color: #f4f4f7;
}

.stepper-1::before {
  background-color: #f4f4f7;
  color: #01c901;
}

.stepper-2 {
  background-color: #3f51b5;
  background: linear-gradient(to right, #3f51b5, #7787e2);
}

.stepper-2::after {
  color: #f4f4f7;
}

.stepper-2::before {
  background-color: #f4f4f7;
  color: #7384df;
}

.stepper-3 {
  background-color: darkred;
  background: linear-gradient(to right, darkred, #f50000);
}

.stepper-3::after {
  color: #f4f4f7;
}

.stepper-3::before {
  background-color: #f4f4f7;
  color: #ed0000;
}

// --REACT-GRID-LAYOUT-STYLE-START--

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item img {
  pointer-events: none;
  user-select: none;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item>.react-resizable-handle {
  position: absolute;
  width: 8px;
  height: 20px;
}

.react-grid-item>.react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 10px;
  height: 10px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-e::after {
  content: "||";
  text-align: center;
  width: 8px;
  height: 20px;
  top: 50%;
  border: none;
  transform: scaleY(2);
}

.react-resizable-hide>.react-resizable-handle {
  display: none;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w,
.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-e {
  right: 0;
  top: 10px;
  height: 100%;
  background-color: #e8e8e8;
  // transform: rotate(315deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n,
.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-grid-item>.react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.sirvez-video-thumbnail {
  width: 180px;
  height: auto;
  position: relative;
}

.sirvez-thumbnail-img {
  width: 100%;
  height: 100%;
}

.youtube-play-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
}

.zmdi-youtube-play:hover {
  cursor: pointer;
}

.noti-modal>.modal-content {
  background-color: transparent;
  height: calc(100vh - 100px);
}

.noti-modal>.modal-content>.row {
  height: 100%;
}

.delivery-file-href:hover {
  text-decoration: none;
}

.calendar-start {
  position: relative;
}

.calendar-start:after {
  position: absolute;
  content: "*";
  color: #ed0000;
  // right: 10px;
}

.calendar-end {
  position: relative;
}

.calendar-end:after {
  position: absolute;
  content: "*";
  color: #ed0000;
}

.event-type:hover {
  transition: all 200ms ease-in;
  transform: scale3d(1.05, 1.15, 1);
}

.event-type-active {
  transition: all 200ms ease-in;
  transform: scale3d(1.05, 1.15, 1);
}

.calendar-new-start {
  position: relative;
}

.calendar-new-start:after {
  position: absolute;
  content: "*";
  color: #ed0000;
  right: -10px;
}

.invite-avatar-badge {
  position: absolute;
  width: 12px;
  height: 12px;
  top: -2px;
  left: 23px;
  border: 2px solid white;
}

.cursor-pointer {
  cursor: pointer;
}

.tableFixHead {
  overflow: auto;
  height: 400px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.goback-detail-helpdesk {
  right: -20px;
}

.goback-detail-helpdesk:hover {
  transform: scale(1.3);
  transition: ease 0.2s all;
}

#__next {
  visibility: hidden;
}

.onboarding-jr-btn {
  color: white !important;
  text-transform: capitalize !important;
  font-size: 28px !important;
}

.sign-up-ad-header {
  font-size: 28px;
  color: #4c4c4c;
  margin-top: 8px;
  margin-bottom: 16px;
}

.sign-up-ad-body .form-check-img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.sign-up-ad-body .form-list {
  color: #152856;
  font-size: 16px;
}

.sign-up-main-title {
  color: white;
  font-size: 26px;
  font-weight: 200;
  margin-top: 30px;
  margin-left: 20px;
}

.signup-main {
  background-color: #152856;
  width: 100%;
}

.trial-box-subtitle {
  letter-spacing: -0.5px;
  font-weight: 300;
}

.sel-organ-box {
  border: 1px solid lightgrey;
  padding: 16px 0;
  margin: 0 5px;
  border-radius: 5px;
}

.xero-connect-organisation {
  padding: 2px 9px;
  border-radius: 3px;
}

.xero-export-btn {
  background-color: #5cac00 !important;
  font-size: 22px !important;
  width: 500px;
  letter-spacing: 5px !important;
}

.xero-export-info {
  border-radius: 3px;
  padding: 3px 5px;
  position: relative;
}

.zmdi-pulse-icon {
  position: absolute;
  top: -10px;
}

.width-90 {
  width: 90px !important;
}

.width-200 {
  width: 200px !important;
}

.width-250 {
  width: 250px !important;
}

.width-400 {
  width: 400px !important;
}

.width-350 {
  width: 350px !important;
}

.width_60 {
  width: 60%;
}

.width_40 {
  width: 40%;
}

.main-left-sidebar .nav-menu-link {
  padding-left: 32px !important;
}

// --REACT-GRID-LAYOUT-STYLE-END--

.search-bar {
  // padding: 20px 40px;
  flex: 1;
}

.search-bar footer {
  display: none;
}

#summary {
  padding: 20px;
}

.results {
  padding: 20px;
}

.container {
  display: flex;
}

.filters {
  flex-basis: 300px;
  min-width: 300px;
  padding: 20px;
  margin-right: 20px;
}

.project-filters {
  flex-basis: 300px;
  min-width: 300px;
  padding: 10px 20px 10px 0;
}

.filters>div {
  text-transform: capitalize;
  margin-bottom: 30px;
}

.font-size-lg {
  font-size: 1.15em !important;
}

.list-style-none {
  list-style: none;
}

.SketchPicker input {
  width: 100% !important;
}

.MuiAccordionSummary-content {
  margin: 2px 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0 !important;
}

.sum-divider {
  height: 2px !important;
  background-color: black !important;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

.MuiButton-root {
  font-family: "Normal",
    "Helvetica",
    "Arial",
    sans-serif !important;
}

.d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-sidebar-reduce {
  height: 100%;
  position: relative;
}

.sidebar-box {
  position: absolute;
  background-color: #1d1d1d;
  transform: translate(0, -50%);
  top: 50%;
  width: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  height: 100px;
  display: flex;
  align-items: center;
}

#react-joyride-step-0 {
  display: none !important;
}

.rbc-event {
  position: relative;
}

.search-box-style {
  border: 0.2em solid #e3e3e3;
  border-radius: 5px;
}

.w-40 {
  width: 40% !important
}

.w-20 {
  width: 20% !important;
}

.calendar-event-badge {
  position: absolute;
  top: 5px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;

}