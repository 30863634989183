@import "./base.scss";
@import url("https://rsms.me/inter/inter.css");

.frf-feedback-container {
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 24px;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    image-rendering: -moz-crisp-edges;

    font-family: "Inter var", -apple-system, BlinkMacSystemFont, Segoe UI,
      Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji,
      Segoe UI Symbol;

    @include transition;
  }

  input,
  button,
  textarea {
    font-size: inherit;
    font-family: inherit;
    -webkit-appearance: none;
  }

  textarea {
    resize: vertical;
  }
}

@media (max-width: 360) {
  .frf-feedback-container {
    * {
      font-size: 12px;
    }
  }
}
