.new-modal-title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 700;
}

a[class^="ais-"] {
  text-decoration: none;
}

.new-modal-lable {
  font-size: 16px;
  font-weight: 700;
}

.font-size-large {
  font-size: large;
}

.w-33 {
  width: 33.3%;
}

.height-30 {
  height: 30px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

.new-modal-lable2 {
  font-size: 14px;
  margin-bottom: 4px;
}

// .product-detail .MuiInputBase-formControl {
//   color: rgba(0, 0, 0, 0.7) !important;
// }

.cursor-pointer {
  cursor: pointer;
}

.form-group {
  position: relative;
}

.calendar-attachment-file {
  cursor: pointer;
  padding: 5px;
}

.calendar-attachment-file:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.border-radius-3 {
  border-radius: 3px;
}

.dashboard-project-lists:hover {
  background-color: rgba(196, 193, 227, 0.4);
}

.line-height-10 {
  line-height: 10px !important;
}

.border-1 {
  border: 1px solid #e3e3e3;
}

.border-bottom-1 {
  border: 1px solid #e3e3e3;
}

.search-title {
  color: rgba(14, 14, 18, 0.7);
  margin-bottom: 0;
  margin-right: 1.5rem;
}

.h-30 {
  height: 30px;
}

.in-out-button {
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  transition: all 0.15s;
  text-align: center;
}

.border-secondary {
  border: 2px solid #e91e63;
}

.border-black {
  border: 2px solid #000;
}

.product-upload-file {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 16px;
}

.new-product-preview .modal-content {
  background-color: transparent;
  border: none;
}

.max-table-width {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rackshelf-highlight {
  border: 2px solid rgba(255, 165, 0, 0.6);
}

.max-width-300 {
  max-width: 300px;
}

.max-width-200 {
  max-width: 200px;
}

.h-50px {
  height: 50px;
}

.mt-100px {
  margin-top: 100px;
}

.min-height-200 {
  min-height: 200px;
}

.font-size-15_ {
  font-size: 15px !important;
}

.wh-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.min-width-300 {
  min-width: 300px;
}

.min-height-75 {
  min-height: 75px;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width_100 {
  width: 100px;
}

.inline-middle {
  display: flex;
  align-items: center;
}

.visibility-hidden {
  visibility: hidden;
}

.font-weight-800 {
  font-weight: 800;
}

.brightness-7 {
  filter: brightness(0.7);
}

.button-border-right {
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid dimgrey;
}

.button-border-right-md {
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid dimgrey;
}

.button-border-right-md:hover {
  cursor: pointer;
}

.button-group-action {
  transition: all 0.15s;
  padding: 2px 6px;
  cursor: pointer;
}

.button-group-action:hover {
  background-color: #efefef;
}

.button-group-action-wrapper {
  margin-right: 4px;
  padding-right: 4px;
  border-right: 1px solid dimgrey;
}

.truncate-container {
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.new-product-badge {
  position: relative;
}

.new-badge {
  position: absolute;
  top: -5px;
  right: -12px;
}

.new-com-badge {
  background-color: rgb(215, 245, 177);
  color: rgb(93, 148, 5);
  padding: 4px 12px;
  line-height: 1;
  margin-left: 5px;
}

.nav-collapse .nav-collapse-btn {
  padding: 8px 25px 9px 20px !important;
  line-height: 1.8 !important;
}

.nav-menu-item .nav-menu-link {
  padding: 8px 25px 9px 20px !important;
  line-height: 1.8 !important;
}

.quick-menu .dropdown-menu.profile-dropdown {
  width: 150px !important;
}

.quick-menu.show .dropdown-menu.profile-dropdown {
  transform: translate3d(0px, 40px, 0px) !important;
}

.profile-list {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
}

.profile-list-item {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
  cursor: pointer;
}

.profile-list-item:hover {
  background-color: rgba(195, 194, 205, 0.4);
}

.container-results {
  width: 100%;
}

.container-filters {
  max-width: 260px;
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebecf3;
}

.container-header.container-options {
  display: block;
}

.container-wrapper {
  margin-right: 25px;
}

.container-body .ais-Panel .ais-Panel-header {
  border-bottom: 1px solid #ebecf3;
}

.container-options {
  margin-bottom: 20px;
  border-bottom: 1px solid #ebecf3;
  min-height: 60px;
}

.container-results .ais-SearchBox-form {
  height: 50px;
}

.container-results .aisHitsPerPage.container-option {
  display: inline-block;
  align-self: flex-end;
}

.hit-image {
  width: 5.5em;
  height: auto;
}

.hit-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.hit.hit-feed {
  border-bottom: 1px solid #e3e3e3;
}

// .hit-feed .hit-image-container {
//   width: 6em;
//   height: 6em;
// }

.hit-stock-cost span {
  font-size: 1.05rem;
  display: flex;
  margin-left: auto;
}

.hit-project .ais-Hits-item {
  background-color: transparent;
}

.hit-project .ais-Hits-item:hover {
  background-color: rgba(195, 194, 205, 0.4);
  cursor: pointer;
}

.feed-summary {
  color: rgba(159, 166, 178, 1);
  font-weight: 500;
}

.hit-info-container strong {
  margin-right: 0.5rem;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
  padding: 0.75rem !important;
}

.ais-RefinementList-count {
  background-color: rgba(63, 65, 74, 0.11) !important;
  border-radius: 4px !important;
  color: rgba(33, 36, 61, 0.8) !important;
  font-weight: 600 !important;
  letter-spacing: 1.1px !important;
  padding: 0 4px !important;
  margin-left: auto !important;
  border: none !important;
}

.calendar-list-item {
  width: 100%;
  color: white;
  text-align: center;
  padding: 0.25rem;
  border-radius: 0.375rem;
  cursor: pointer;
}

.container-results .container-footer {
  margin: 1.2rem 0;
}

.ais-Pagination-list {
  display: flex;
  justify-content: center;
}

.result-description .product-name:hover {
  text-decoration: underline;
}

.feed-app-frame {
  position: relative;
}

.feed-detail-loading {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.result-info {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.custom-toggle-refinement {
  border-bottom: 1px solid #ebecf3;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
}

.ais-ToggleRefinement-labelText {
  font-size: 16px;
}

.distributor-image-logo {
  height: 50px;
  width: auto;
}

.description-footer {
  display: flex;
  align-items: center;
}

.custom-results {
  // border: 1px solid #e3e3e3;
  width: 100%;
}

.icon-stepper {
  font-size: 22px;
  padding-right: 12px;
  padding-left: 12px;
}

.mr-20p {
  margin-right: 20px;
}

// .Select-menu {
//   max-height: 150px !important;
// }

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 4.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 4.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.mt-11,
.my-11 {
  margin-top: 5rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 5rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 5rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 5rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 6rem !important;
}

.to-company-info:hover {
  text-decoration: underline;
}

.product-highlight {
  box-shadow: inset 0 0 0 0.4em #f4aab9;
}

ul.feed-list {
  list-style: none;
  padding: 0.5rem !important;
  font-size: 15px;
}

ul.feed-list>li {
  cursor: pointer;
  padding: 0.2rem !important;
}

ul.feed-list>li:hover,
ul.feed-list>li:active {
  background-color: #eaeaea;
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  // background: #fff;
  background-color: #6c757d;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.font-1 {
  font-size: 0.8rem !important;
}

.font-2 {
  font-size: 0.9rem !important;
}

.font-3 {
  font-size: 1rem !important;
}

.font-4 {
  font-size: 1.1rem !important;
}

.font-5 {
  font-size: 1.2rem !important;
}

.font-6 {
  font-size: 1.3rem !important;
}

.font-7 {
  font-size: 1.4rem !important;
}

.font-8 {
  font-size: 1.5rem !important;
}

.font-9 {
  font-size: 1.6rem !important;
}

.font-10 {
  font-size: 1.8rem !important;
}

.line-height-1 {
  line-height: 1rem
}

.line-height-2 {
  line-height: 1.2rem;
}

.width-120 {
  width: 120px;
}

.width-150 {
  width: 150px;
}

.width-500 {
  width: 500px;
}

.width-90 {
  width: 90px !important;
}

.w-95 {
  width: 95%;
}

.bg-dark-grey {
  background-color: #aaaaaa !important;
}

.bg-light-red {
  background-color: #ff9999;
}

.badge.badge-light-warning {
  color: #ff9f43 !important;
  background-color: rgba(255, 159, 67, 0.12);
}

.badge.badge-light-success {
  color: #28c76f !important;
  background-color: rgba(40, 199, 111, 0.12);
}

.badge.badge-light-black {
  color: #82868b !important;
  background-color: rgba(130, 134, 139, 0.12);
}

.badge.badge-light-danger {
  color: #ea5455 !important;
  background-color: rgba(234, 84, 85, 0.12);
}

.badge.badge-light-purple {
  color: #7367f0 !important;
  background-color: rgba(115, 103, 240, 0.12);
}

.alert-dismissible .close {
  padding: 0.5rem 1.25rem !important;
}

.workflow-editor .rdw-editor-toolbar {
  margin-bottom: 0 !important;
  border: 1px solid lightgrey;
  border-bottom: 0;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}